import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.jsx';
import { BrowserRouter } from 'react-router-dom';
import registerServiceWorker from './registerServiceWorker';
import NoteState from './context/notes/noteState';


const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(<BrowserRouter><NoteState><App/></NoteState></BrowserRouter> );
        registerServiceWorker();